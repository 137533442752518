import React, { useState, useEffect } from 'react';
import InteraktWidget from './InteraktChat';
const Footer = () => {
  const currentYear = new Date().getFullYear();

  // State to control the visibility of the "Back to Top" button
  const [showButton, setShowButton] = useState(false);

  // Function to handle the scroll to top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Show the "Back to Top" button only when the user scrolls down
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <footer className="footer-part footer-bg-light">
        <div className="footer-widget">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-12">
                <div className="footer-logo text-center">
                  <a href="/"><img src="assets/images/logo.png" alt="Logo" className="mb-3" /></a>
                  <ul className="footer-widget-link pt-3">
                    <li><a href="/"><i className="fa fa-angle-double-right"></i> Home</a></li>
                    <li><a href="/about us"><i className="fa fa-angle-double-right"></i> About us</a></li>
                    <li><a href="legal"><i className="fa fa-angle-double-right"></i> Disclaimer</a></li>
                    <li><a href="/contact us"><i className="fa fa-angle-double-right"></i> Contact Us</a></li>
                    <li><a href="/privacypolicy"><i className="fa fa-angle-double-right"></i> Privacy & Policy</a></li>
                    <li><a href="/terms&conditions"><i className="fa fa-angle-double-right"></i> Terms & conditions</a></li>
                    <li><a href="/faq"><i className="fa fa-angle-double-right"></i> FAQ</a></li>
                  </ul>
                  <p className="text-muted text-center mb-0 pt-3 pb-0"><b>Connect with us</b></p>
                  <ol className="flat-list mt-0 pt-2">
                        <li><a target="_blank" href="https://www.facebook.com/seagull.jobs/"><i className="fab fa-facebook-f"></i></a></li>
						<li><a target="_blank" href="https://twitter.com/hrd1_seagull"><i><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
</svg></i></a></li>
						<li><a target="_blank" href="https://www.instagram.com/seagulljobs/"><i className="fab fa-instagram"></i></a></li>
						<li><a target="_blank" href="https://www.linkedin.com/company/seagull-jobs/"><i className="fab fa-linkedin"></i></a></li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <p>
                  Copyright © <span>{currentYear}</span> | All rights reserved <a href="#!">Seagulljobs</a>
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Back to Top Button - visible only when showButton is true */}
        {showButton && (
          <div className="backtotop" onClick={scrollToTop}>
            <i className="fa fa-angle-up backtotop_btn"></i>
          </div>
        )}
      </footer>
    </div>
  );
};

export default Footer;
