import React, { useState, useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import Dropdown from './components/Dropdown';
import DropdownExp from './components/DropdownExp';
import DropdownLocation from './components/DropdownLocation';
import Autocomplete from './components/Autocomplete';
const BASE_URL=process.env.REACT_APP_BASE_URL;
const BannerSlider = () => {
	const [msgData, setMsgData] = useState('');
	const [designationId, setDesignationId] = useState(null);
	const [options, setOptions] = useState([]);
	const [formData, setFormData] = useState({
		skill: '',  // Changed from 'skils' to 'skill'
		location: '',
		exp: ''
	  });
	  const navigate = useNavigate();
	  const handleDropdownChange = (name, value) => {
		setFormData({ ...formData, [name]: value });
	  };
	  useEffect(() => {
		// Fetch designations from the server
		const fetchDesignations = async () => {
		  try {
			const response = await fetch(`${BASE_URL}getDesignationsPopular`, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json',
			  },
			});
	
			if (response.ok) {
			  const data = await response.json();
			  setOptions(data); // Populate options with fetched designations
			} else {
			  console.error('Failed to fetch designations');
			}
		  } catch (error) {
			console.error('Error fetching designations:', error);
		  }
		};
	
		fetchDesignations();
	  }, []);
	  const handleSubmitItem = async (item) => {
		// Assuming formData will now include selected `item` data
		const selectedFormData = {
		  skill: item.id,
		  location: '',
		  exp: ''
		};
	  
		console.log('Submitting form data:', selectedFormData); // For debugging
	  
		try {
		  const response = await fetch(`${BASE_URL}getJobs`, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify(selectedFormData),  // Send updated formData
		  });
	  
		  if (response.ok) {
			const data = await response.json();
			sessionStorage.setItem('jobs', JSON.stringify(data));
			navigate('/jobsearch', { state: { response: data } });
		  } else {
			setMsgData('No Result Found, Please Try another');
			console.error('No result for such criteria.');
		  }
		} catch (error) {
		  setMsgData('No Result Found, Please Try another');
		  console.error('Error submitting form', error);
		}
	  };	
	  const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Submitting form data:', formData);  // For debugging
		
		try {
		 //  const response = await fetch('http://localhost:8000/api/getJobs', {
			const response = await fetch(`${BASE_URL}getJobs`, {
		
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json'
			},
			body: JSON.stringify(formData)
		  });
	  
		  if (response.ok) {
			const data = await response.json();
			sessionStorage.setItem('jobs', JSON.stringify(data));
			navigate('/jobsearch', { state: { response: data } });
		  } else {
			//alert("h");
			setMsgData('No Result Found, Please Try another');
			console.error('No Result for such Criteria..');
		  }
		} catch (error) {
			setMsgData('No Result Found, Please Try another');
		  console.error('Error submitting form', error);
		}
	  };
	  const handleSkillChange = (selectedId) => {
		setFormData(prevData => ({
		  ...prevData,
		  skill: selectedId
		}));
		setDesignationId(selectedId);
	  };
  return (

	<section className="tf-slider sl3 over-flow-hidden">
		<div className="container-fluid mapview">
			<div className="row justify-content-center align-items-center">
			<div className="col-lg-11">
			<div className="row justify-content-center align-items-center">
				<div className="col-lg-8 col-md-12">
					<div className="content wow fadeInUp">
						<div className="heading">
							<h2 className="text-white">Your next career… <br></br>starts here!</h2>
							</div>
						<div className="form-sl">
						<div className="form-view form-sl1">
							<form onSubmit={handleSubmit}>
								<div className="row">
									<div className="col-lg-10">
										<div className="row row-group-search home1 st">
											<div className="col-lg-4 p-2 form-group-1 form-group jobname">
										{/* <Dropdown onChange={(value) => handleDropdownChange('skils', value)} />  */}
										<Autocomplete
											value={formData.skill}
											onChange={handleSkillChange}
											/>
											</div> 
											<div className="col-lg-4 p-2 form-group form-group-1">
												<DropdownExp  onChange={(value) => handleDropdownChange('exp', value)}  />
										
											</div>
											<div className="col-lg-4 p-2 form-group form-group-1">
												<DropdownLocation designationId={designationId}  onChange={(value) => handleDropdownChange('location', value)} />
									
											</div>
										</div>
									</div>
									<div className="col-lg-2">
										<div className="row row-group-search home1 st">
											<div className="col-lg-12 p-2 form-group form-group-1"><button className="btn btn-1 btn-find">Find Jobs</button></div>
										</div>
									</div>
								
								</div>
								<span style={{position: "absolute",color:"red"}}>{msgData}</span>
							</form>
							
							</div>
							<h5 className="pt-3 text-white">POPULAR SEARCHES:
							</h5>
							<ul className="pt-2 list-category text-white">
  {options.slice(0, 5).map((item, index) => (
    <li key={index} >
      <a href="##" onClick={() => handleSubmitItem(item)} >{item.designation}</a>
    </li>
  ))}
</ul>
						</div>
					</div>
				</div>	
				<div className="col-lg-4">
<div className="wd-review-job thumb2 widget-counter tf-sl3">
<div className="thumb">
<div className="box1">
<img src="assets/images/home/thumb2.png" alt="images" />
<div className="markk ani3">
<img src="assets/images/home/download.png" alt="images" />
</div>
</div>
<div className="box2">
<img src="assets/images/home/thumb1.png" alt="images" />
<div className="markk ani3">
<img src="assets/images/home/download1.png" alt="images" />
</div>
</div>
</div>
<div className="tes-box ani4">
<div className="client-box">
<div className="content"><h6 className="number wrap-counter">200+</h6><div className="des">Recruiters</div></div>
</div>
</div>
<div className="icon1 ani3">
<img src="assets/images/home/download2.png" alt="images" /></div>
<div className="icon6 ani3"><img src="assets/images/home/download3.png" alt="images" /></div>
<div className="icon2 ani4"><div className="chart-boxs counter">
<div className="content "><h6 className="number wrap-counter">100K+</h6><div className="des">Candidates</div></div>
	</div>
</div>
<div className="icon3 ani6"><img src="assets/images/home/download4.png" alt="images" /></div>
<div className="chart-box ani4 counter">
<div className="content "><h6 className="number wrap-counter">5000+</h6><div className="des">Jobs</div></div>
	</div>
	</div>
	</div>
				
			</div>	
			</div>	
			</div>	
		</div>	
	</section>	
		
	 
	 
	 
//  <section className="banner-3-part">
//       <Swiper
//         loop={true}
//         effect="fade"
//         speed={800}
//         autoplay={{ delay: 5000 }}
//         pagination={{ clickable: true }}
//         navigation={true}
//         modules={[Autoplay, EffectFade, Pagination, Navigation]}
//       >
//         <SwiperSlide className="banner-3-item">
//           <img src="assets/images/bg/slide.png" alt="Banner Image 1" />
//         </SwiperSlide>
//         <SwiperSlide className="banner-3-item">
//           <img src="assets/images/bg/banner2.jpg" alt="Banner Image 2" />
//         </SwiperSlide>
//         <SwiperSlide className="banner-3-item">
//           <img src="assets/images/bg/banner3.jpg" alt="Banner Image 3" />
//         </SwiperSlide>
// 		<SwiperSlide className="banner-3-item">
//           <img src="assets/images/bg/banner1.jpg" alt="Banner Image 3" />
//         </SwiperSlide>
//       </Swiper>
//       <div className="banner-overlay-form">
// 			<div className="container">
// 				<div className="row">
// 					<div className="banner-contact">
// 						<div className="brand-color mb-3"><img src="assets/images/tagline.png" /></div>
// 						<div className="form-view">
// 							<form  onSubmit={handleSubmit}>
							
// 							<div className="form-group jobname">
// 							 {/* <Dropdown onChange={(value) => handleDropdownChange('skils', value)} />  */}
// 							 <Autocomplete
// 								value={formData.skill}
// 								onChange={handleSkillChange}
// 								/>
// 								</div> 
// 								<div className="form-group ">
// 									<DropdownExp  onChange={(value) => handleDropdownChange('exp', value)}  />
							
// 								</div>
// 								<div className="form-group">
// 									<DropdownLocation designationId={designationId}  onChange={(value) => handleDropdownChange('location', value)} />
						
// 								</div>
// 								<button className="btn-1">SEARCH</button>
								
// 							</form>
// 							<span style={{position: "absolute",color:"red",marginLeft:"-120px"}}>{msgData}</span>
// 						</div>
// 					</div>
// 				</div>
				
// 			</div>
// 		</div>
//     </section>
	
  );
};

export default BannerSlider;
