import React, { useState, useEffect } from 'react';
import { Link, Routes, Route, Router, Switch, useNavigate, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Header from './Header';
import Footer from './Footer';
import BannerSlider from './BannerSlider';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, EffectFade, Pagination, Navigation } from 'swiper/modules';
import ShareButtons from './components/ShareButtons';
import { toast, Toaster } from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Home = () => {
	const navigate = useNavigate();
	const [jobData, setJobData] = useState([]);
	const [msgData, setMsgData] = useState('');
	const [candidate, setCandidate] = useState(0);
	const [options, setOptions] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [virtualTour, setVirtualTour] = useState(() => {
		// Initialize state with session value if available
		return sessionStorage.getItem("virtualTour") || 0;
	});

	useEffect(() => {
		// Fetch designations from the server
		const fetchDesignations = async () => {
			try {
				const response = await fetch(`${BASE_URL}getDesignationsPopular`, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				});

				if (response.ok) {
					const data = await response.json();
					setOptions(data); // Populate options with fetched designations
				} else {
					console.error('Failed to fetch designations');
				}
			} catch (error) {
				console.error('Error fetching designations:', error);
			}
		};

		fetchDesignations();
	}, []);

	useEffect(() => {
		const virtualTour1 = sessionStorage.getItem('virtualTour');
		if (virtualTour1) {
			//alert(virtualTour1);
			setVirtualTour(virtualTour1);
		}
	}, []);
	useEffect(() => {

		const candidateId1 = sessionStorage.getItem('candidateId')

		if (candidateId1) {
			setCandidate(candidateId1);
		}
		else { setCandidate(0); }
	}, []);

	useEffect(() => {

		if (virtualTour == 1) {
			setShowModal(true);
		}
	}, [virtualTour]);

	const closeModal = async () => { // Add `async` here
		sessionStorage.setItem("virtualTour", "2");
		setVirtualTour(2);
		setShowModal(false); // Close the modal when user interacts

		const candidateId1 = sessionStorage.getItem("candidateId");
		if (candidateId1) {
			try {
				const response = await axios.post(`${BASE_URL}updateVirtualTour`, {
					candidate_id: candidateId1,
					virtual_tour: 2,
				});

				if (response.data.success) {
					console.log("Virtual tour updated successfully");
				} else {
					sessionStorage.setItem("virtualTour", "0");
					console.error("Failed to update virtual tour");
				}
			} catch (error) {
				console.error("Error updating virtual tour:", error);
				sessionStorage.setItem("virtualTour", "0");
			}
		}
	};

	useEffect(() => {
		const fetchJobs = async () => {
			try {
				const response = await fetch(`${BASE_URL}getJobsAll`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					}
				});

				if (response.ok) {
					const data = await response.json();
					setJobData(data); // Set fetched data to state
				} else {
					setMsgData('No Result Found, Please Try another');
					console.error('No Result for such Criteria.');
				}
			} catch (error) {
				setMsgData('No Result Found, Please Try another');
				console.error('Error submitting form', error);
			}
		};

		fetchJobs(); // Call the function to fetch data when the component mounts
	}, []); // Empty dependency array to run only once on component mount
	const concatenateStrings = (str1, str2, str3) => {

		const parts = [str1, str2, str3].filter(part => part !== null && part !== 'null' && part !== undefined && part !== '');

		//alert(parts);
		return parts.join('/');
	};
	function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'short' });
		const year = date.getFullYear();

		// Get the ordinal suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
		const getOrdinalSuffix = (day) => {
			if (day > 3 && day < 21) return 'th'; // for 11th, 12th, 13th, etc.
			switch (day % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		};

		return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
	}
	const handleClick = async (e, idd, desigid) => {

		e.preventDefault();

		//alert(JSON.stringify(formData));

		const formData = {
			jobId: idd,
			designationId: desigid,
			candidateId: candidate
		};
		// alert(JSON.stringify(formData));
		try {

			//const response = await fetch('http://localhost:8000/api/getJobDetails', {
			const response = await fetch(`${BASE_URL}getJobDetails`, {

				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(formData)
			});
			if (response.ok) {

				const data = await response.json();
				sessionStorage.setItem('jobdetails', JSON.stringify(data));
				navigate('/jobdetails', { state: { response: data } });

			} else {
				console.error('Failed to submit form');
			}
		} catch (error) {
			console.error('Error submitting form', error);
		}
	};
	const handleBtnClick = async (e, idd) => {

		e.preventDefault();

		//alert(JSON.stringify(formData));
		if (candidate > 0) {
			const formData = {
				jobId: idd,
				candidateId: candidate
			};
			// alert(JSON.stringify(formData));
			try {

				//const response = await fetch('http://localhost:8000/api/getJobDetails', {
				const response = await fetch(`${BASE_URL}saveBookmark`, {

					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(formData)
				});
				const data = await response.json();
				if (response.ok) {


					sessionStorage.setItem('bookMarkdetails', JSON.stringify(data));
					toast.success("Saved this Jobs To Bookmark");

				} else {
					console.error('Failed to submit form:', data.message || response.statusText);
					toast.error(data.message || 'Failed to save the bookmark. Please try again.');
				}
			} catch (error) {
				console.error('Error submitting form:', error);
				toast.error('An error occurred. Please try again later.');
			}
		} else {
			toast.success("Please Login or SignUp!");
		}
	};
	const handleSubmit = async (item) => {
		// Assuming formData will now include selected `item` data
		const selectedFormData = {
			skill: item.id,
			location: '',
			exp: ''
		};

		console.log('Submitting form data:', selectedFormData); // For debugging

		try {
			const response = await fetch(`${BASE_URL}getJobs`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(selectedFormData),  // Send updated formData
			});

			if (response.ok) {
				const data = await response.json();
				sessionStorage.setItem('jobs', JSON.stringify(data));
				navigate('/jobsearch', { state: { response: data } });
			} else {
				setMsgData('No Result Found, Please Try another');
				console.error('No result for such criteria.');
			}
		} catch (error) {
			setMsgData('No Result Found, Please Try another');
			console.error('Error submitting form', error);
		}
	};
	const job_grid_container = {
		display: 'grid',
		gridTemplateColumns: 'repeat(3, 1fr)', /* 3 columns */
		gap: '20px', /* Spacing between items */
		padding: '20px'
	};

	return (
		<div>
			<Header />
			<BannerSlider />


			<Toaster toastOptions={{ duration: 4000 }} />
			<section className="clint-logo-3 section-p">
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="section-head text-center">
							<h2>Premium Jobs</h2>
							{msgData && <p className="text-center pt-5">{msgData}</p>}
						</div>
					</div>
					<div className="row justify-content-center">

						{jobData.length > 0 ? (
							jobData.slice(0, 12).map((job, index) => (

								<div class="col-lg-3 col-md-6 col-12" key={index}>
									<div class="job-post job-type-three rounded shadow bg-white p-4">

										<div class="d-flex justify-content-between">
											<div>
												<p class="text-muted float-start mb-0">Posted On: {formatDate(`${job.posteddate}`)}</p>
											</div>


											<ul class="list-unstyled align-items-center mb-0">
												{/* <li class="list-inline-item"><a href="javascript:void(0)" class="like"><i class="fa fa-heart align-middle fs-3"></i></a></li> */}
												<li class="list-inline-item"><a href="javascript:void(0)" onClick={(e) => handleBtnClick(e, job.id)} class="btn btn-icon btn-sm btn-soft-primary"><i class="fa fa-bookmark"></i></a></li>
												{/* <li class="list-inline-item"><a href="javascript:void(0)" onClick={(e) => handleClick(e, job.id, job.designationid)} class="btn btn-icon btn-sm btn-soft-primary"><i class="fa fa-arrow-right arrowbox" ></i></a></li>  */}
											</ul>
										</div>
										<a href="javascript:void(0)" onClick={(e) => handleClick(e, job.id, job.designationid)}>

											<div class="mt-2">
												<a href="job-detail-three.html" class="text-dark title h5">{job.designation}</a>
												<p class="text-muted mt-2 jp">Education:{concatenateStrings(`${job.requiredqualification1}`, `${job.requiredqualification2}`, `${job.requiredqualification3}`)}&nbsp;<br />
													<i class="fa fa-map-marker me-1"></i>{job.location_name}</p>
												<ul class="list-unstyled mb-0" style={{ fontSize: "8px" }}>
													<li class="d-inline-block me-1"><a href="" class="badge bg-primary">{job.jobtype}</a></li>
													<li class="d-inline-block me-1"><a href="" class="badge bg-primary">{job.currency}.{job.minbasicsalary}</a></li>
													<li class="d-inline-block me-1"><a href="" class="badge bg-primary">Openings: {job.numberofposts}</a></li>
												</ul>
											</div>
										</a>
									</div>

								</div>

							))
						) : (
							<div className="row"> <div className="col-lg-12 text-center"><p>No jobs available</p></div></div>
						)}
					</div>
				</div>
			</section>
			{/* 
<section className="clint-logo-3 section-p">
      <div className="container">
        <div className="row justify-content-center">
          <div className="section-head text-center">
            <h2>Premium Jobs</h2>
			 {msgData && <p className="text-center pt-5">{msgData}</p>}
          </div>
		  </div>
		  <div className="row justify-content-center">
<div className="job_grid">
  {jobData.length > 0 ? (
    jobData.slice(0, 12).map((job, index) => (
      <div  key={index}>
        <a href="javascript:void(0)" onClick={(e) => handleClick(e, job.id, job.designationid)}>
       
            <div className="cust-job-tuple layout-wrapper lay-1">
              
              <div className="row2">
                <a className="title">{job.designation}</a>
              </div>
              <div className="row3">
                <span className="comp-dtls-wrap">
                  <span className="comp-name mw-100">
                    {concatenateStrings(`${job.requiredqualification1}`, `${job.requiredqualification2}`, `${job.requiredqualification3}`)}
                  </span>
                </span>
              </div>
              <div className="row4">
                <div className="job-details">
                  <span className="loc-wrap fa fa-map-marker">
                    <span className="loc">{job.location_name}</span>
                  </span>
                </div>
              </div>
			  <div className="row1" style={{ width: "250px", textAlign: "left" }}>
                <span className="job-post-day">
                  Posted On: {formatDate(`${job.posteddate}`)} <br /> Openings: {job.numberofposts}
                </span>
              </div>
			  
            </div>
		
        </a>
      </div>
    ))
  ) : (
    <div className="row"> <div className="col-lg-12 text-center"><p>No jobs available</p></div></div>
  )}
</div>


            </div>
        </div>
  
</section>
 */}

			<section className="counter-3-part section-p  twm-new-sub-section-wrap   bg-counter ">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<div className="section-head text-white">
								<h2 className="text-white">Our Experience</h2>
							</div>
						</div>
					</div>
					<div className="row  h-page-6-getjobs-wrap align-items-center justify-content-center">
						<div className="col-lg-10">
							<div className="row">
								<div className="col-md-3 col-6 text-center">
									<div className="counter-3-item">
										<div className="number-box">
											<i className="flaticon-map-pin-marked"></i>
										</div>
										<div className="number-box">
											<h2 className="white counter">15</h2>
											<h3>Branches</h3>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-6 text-center">
									<div className="counter-3-item">
										<div className="number-box">
											<i className="flaticon-network"></i>
										</div>
										<div className="number-box">
											<h2 className="white counter">300000</h2>
											<h3>Mobilization</h3>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-6 text-center">
									<div className="counter-3-item">
										<div className="number-box">
											<i className="fa fa-handshake-o" aria-hidden="true"></i>
										</div>
										<div className="number-box">
											<h2 className="white counter">500</h2>
											<h3>Clients</h3>
										</div>
									</div>
								</div>

								<div className="col-md-3 col-6 text-center">
									<div className="counter-3-item">
										<div className="number-box">
											<i className="fa fa-globe"></i>
										</div>
										<div className="number-box">
											<h2 className="white counter">20</h2>
											<h3>Countries</h3>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>







			{/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button> */}
			{showModal && (
				<div
					className="modal fade show"
					id="exampleModal"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="exampleModalLabel"
					aria-hidden="true"
					style={{
						display: "block",
						backgroundColor: "rgba(0, 0, 0, 0.5)",
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						zIndex: 1050,
					}}
				>
					<div
						className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
						role="document"
					>
						<div className="modal-content">
							<div className="modal-header tf-bg-color">
								<h4
									className="modal-title text-white"
									id="exampleModalLabel"
								>
									Explore our website with a virtual tour
								</h4>
								<button
									type="button"
									className="close text-white"
									aria-label="Close"
									onClick={closeModal}
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<div
									id="carouselExampleControls"
									className="carousel slide"
									data-ride="carousel"
									style={{
										border: "1px solid #d31b57",
										borderRadius: "10px",
									}}
								>
									<div
										className="carousel-inner"
										style={{
											border: "1px solid #d31b57",
											borderRadius: "10px",
										}}
									>
										<div className="carousel-item active">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide1.jpg"
												alt="First slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide2.jpg"
												alt="Second slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide3.jpg"
												alt="Third slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide4.jpg"
												alt="Fourth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide5.jpg"
												alt="Fifth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide6.jpg"
												alt="Second slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide7.jpg"
												alt="Third slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide8.jpg"
												alt="Fourth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide9.jpg"
												alt="Fifth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide10.jpg"
												alt="Second slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide11.jpg"
												alt="Third slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide12.jpg"
												alt="Fourth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide13.jpg"
												alt="Fifth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide14.jpg"
												alt="Fourth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide15.jpg"
												alt="Fifth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide16.jpg"
												alt="Second slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide17.jpg"
												alt="Third slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide18.jpg"
												alt="Fourth slide"
											/>
										</div>
										<div className="carousel-item">
											<img
												className="d-block w-100"
												src="assets/images/tour/Slide19.jpg"
												alt="Fifth slide"
											/>
										</div>
									</div>
									<a
										className="carousel-control-prev"
										href="#carouselExampleControls"
										role="button"
										data-slide="prev"
									>
										<span
											className="carousel-control-prev-icon"
											aria-hidden="true"
										></span>
										<span className="sr-only">Previous</span>
									</a>
									<a
										className="carousel-control-next"
										href="#carouselExampleControls"
										role="button"
										data-slide="next"
									>
										<span
											className="carousel-control-next-icon"
											aria-hidden="true"
										></span>
										<span className="sr-only">Next</span>
									</a>
								</div>
							</div>
							<div className="modal-footer">
								<button
									type="button"
									className="btn btn-primary"
									onClick={closeModal}
								>
									Skip
								</button>
							</div>
						</div>
					</div>
				</div>
			)}


			{/* <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable" role="document">
    <div className="modal-content">
      <div className="modal-header tf-bg-color ">
        <h4 className="modal-title text-white" id="exampleModalLabel">Explore our website with a virtual tour</h4>
        <button type="button" className="close  text-white" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" style={{ border: '1px solid #d31b57', borderRadius: '10px'}}>
              <div className="carousel-inner" style={{ border: '1px solid #d31b57', borderRadius: '10px'}}>
                <div className="carousel-item active">
                  <img className="d-block w-100" src="assets/images/tour/1.jpg" alt="First slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="assets/images/tour/2.jpg" alt="Second slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="assets/images/tour/3.jpg" alt="Third slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="assets/images/tour/4.jpg" alt="Third slide"/>
                </div>
                <div className="carousel-item">
                  <img className="d-block w-100" src="assets/images/tour/5.jpg" alt="Third slide"/>
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary" data-dismiss="modal" aria-label="Close">Skip</button>
      </div>
    </div>
  </div>
</div> */}



			<section className="practise-parts section-p">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<div className="section-head">
								<h2>POPULAR SEARCHES</h2>
							</div>
						</div>
					</div>
					<div className="row align-items-center justify-content-center">
						<div className="col-lg-12">
							<div className="searchlist">

								{options.map((item, index) => (
									<div className="searchitem" key={index}>
										<a href="#!" className="Searchitemsview" onClick={() => handleSubmit(item)} >{item.designation}</a>
									</div>
								))}

							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="clint-logo section-p">
				<div className="container">
					<div className="row justify-content-center">
						<div className="section-head text-center">
							<h2>Top Recruiters</h2>
							{/* <ShareButtons /> */}
						</div>
						<div className="row justify-content-center">
							<div className="col-lg-2 col-4 1"><img className="clientele-logo" src="assets/images/clients/descon.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 2"><img className="clientele-logo" src="assets/images/clients/2.png" alt="" /> </div>
							<div className="col-lg-2 col-4 3"><img className="clientele-logo" src="assets/images/clients/3.png" alt="" /> </div>
							<div className="col-lg-2 col-4 4"><img className="clientele-logo" src="assets/images/clients/4.png" alt="" /> </div>
							<div className="col-lg-2 col-4 5"><img className="clientele-logo" src="assets/images/clients/altradcape.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 6"><img className="clientele-logo" src="assets/images/clients/baskinrobbins.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 7"><img className="clientele-logo" src="assets/images/clients/samsung.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 8"><img className="clientele-logo" src="assets/images/clients/drydocks.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 9"><img className="clientele-logo" src="assets/images/clients/kaefer.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 10"><img className="clientele-logo" src="assets/images/clients/nps.png" alt="" /> </div>
							<div className="col-lg-2 col-4 11"><img className="clientele-logo" src="assets/images/clients/doosan.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 12"><img className="clientele-logo" src="assets/images/clients/sts.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 13"><img className="clientele-logo" src="assets/images/clients/galdari.png" alt="" /> </div>
							<div className="col-lg-2 col-4 14"><img className="clientele-logo" src="assets/images/clients/emrill.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 15"><img className="clientele-logo" src="assets/images/clients/galfar.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 16"><img className="clientele-logo" src="assets/images/clients/lamprell.jpg" alt="" /> </div>
							{/* <div className="col-lg-2 col-4 17"><img className="clientele-logo"  src="assets/images/clients/steel.png" alt="" /> </div>	 */}
							<div className="col-lg-2 col-4 19"><img className="clientele-logo" src="assets/images/clients/bec.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 20"><img className="clientele-logo" src="assets/images/clients/arabian.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 21"><img className="clientele-logo" src="assets/images/clients/oman.png" alt="" /> </div>
							<div className="col-lg-2 col-4 22"><img className="clientele-logo" src="assets/images/clients/SAS.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 23"><img className="clientele-logo" src="assets/images/clients/alshaya.jpg" alt="" /> </div>
							{/* <div className="col-lg-2 col-4 24"><img className="clientele-logo"  src="assets/images/clients/al ahelia.png" alt="" /> </div> */}
							<div className="col-lg-2 col-4 25"><img className="clientele-logo" src="assets/images/clients/nbtc.jpg" alt="" /> </div>
							<div className="col-lg-2 col-4 26"><img className="clientele-logo" src="assets/images/clients/mc don.png" alt="" /> </div>
							<div className="col-lg-2 col-4 27"><img className="clientele-logo" src="assets/images/clients/madina.png" alt="" /> </div>
							<div className="col-lg-2 col-4 28"><img className="clientele-logo" src="assets/images/clients/adani.png" alt="" /> </div>
							<div className="col-lg-2 col-4 29"><img className="clientele-logo" src="assets/images/clients/reliance jio.png" alt="" /> </div>
							<div className="col-lg-2 col-4 30"><img className="clientele-logo" src="assets/images/clients/shappo.png" alt="" /> </div>
							<div className="col-lg-2 col-4 31"><img className="clientele-logo" src="assets/images/clients/dopet.png" alt="" /> </div>
							<div className="col-lg-2 col-4 32"><img className="clientele-logo" src="assets/images/clients/ramsis en.png" alt="" /> </div>
							<div className="col-lg-2 col-4 33"><img className="clientele-logo" src="assets/images/clients/mcsc.jpg" alt="" /> </div>

						</div>
					</div>
				</div>

			</section>
			<div class="section-full  section-p twm-for-employee-9-area site-bg-white">
				<div class="container">
					<div class="section-content">
						<div class="twm-for-employee-9">
							<div class="row">

								<div class="col-lg-5 col-md-12">
									<div class="twm-explore-9-media-wrap">
										<div class="twm-media">
											<img src="assets/images/seagull_logo.png" alt="" />
											<div class="rectangle1-wrap">
												<div class="rectangle1 rotate-center"></div>
											</div>
											<div class="rectangle2-wrap">
												<div class="rectangle2"></div>
											</div>

										</div>
									</div>
								</div>

								<div class="col-lg-7 col-md-12">
									<div class="twm-explore-content-outer-3">

										<div class="twm-explore-content-3">
											{/* <div class="twm-title-small">Explore Our Seagull</div> */}
											<div class="twm-title-large">
												<h2>Seagull Group of Companies</h2>
												<p>Welcome to Seagull International, where pursuing excellence is a dynamically refreshed habit, not just a random occurrence. Give us the privilege to bridge the gap between employer and employee.
												</p>
											</div>
											<div class="twm-upload-file">
												<a target="_blank" href="https://www.seagullgroup.in/" class="btn Loginbtn">Visit Site</a>
											</div>

										</div>

									</div>
								</div>

							</div>
						</div>
					</div>

				</div>
			</div>
			<section className="blog-area section-p">
				<div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<div className="section-head">
								<h2>LATEST BLOGS</h2>
							</div>
						</div>
					</div>
					<div className="row">

						<div className="col-md-6 col-lg-6 col-xl-6">
							<div className="sin-blog-two justify-content-center align-items-center row no-gutters">
								<div className="col-xl-6">
									<div className="blog-img-two ">
										<img src="https://www.seagullgroup.in/uploads/career/172209741120240727162331.jpg" alt="" />
									</div>
								</div>
								<div className="col-xl-6">
									<div className="blog-content-two-wrap ">
										<div className="blog-con-two">
											<h2 className="mb-2"><a target="_blank" href="https://seagullgroup.in/blog/10/india%27s-ambitious">India's Ambitious Skill Development Initiatives: Boosting Economy and Empowering Youth:</a></h2>

											<a className="text-danger" target="_blank" href="https://seagullgroup.in/blog/10/india%27s-ambitious">Click here to Read blog</a>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-md-6 col-lg-6 col-xl-6">
							<div className="sin-blog-two justify-content-center align-items-center row no-gutters">
								<div className="col-xl-6">
									<div className="blog-img-two ">
										<img src="https://www.seagullgroup.in/uploads/career/171168867120240329050431.jpg" alt="" />
									</div>
								</div>
								<div className="col-xl-6">
									<div className="blog-content-two-wrap ">
										<div className="blog-con-two">
											<h2 className="mb-2"><a target="_blank" href="https://seagullgroup.in/blog/9/living-in-ai-era">Living in the AI Era: Embracing the Future:</a></h2>
											<a className="text-danger" target="_blank" href="https://seagullgroup.in/blog/9/living-in-ai-era">Click here to Read blog</a>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>
				</div>
			</section>


			<Footer />
		</div>
	)
}

export default Home